"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = initUserEditableList;
class UserEditableList {
    constructor(submitButton, closeButton, errorNotice, itemsObject, checkboxes, userId, moduleId, metaKey) {
        var _a, _b;
        Object.defineProperty(this, "submitButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: submitButton
        });
        Object.defineProperty(this, "closeButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: closeButton
        });
        Object.defineProperty(this, "errorNotice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: errorNotice
        });
        Object.defineProperty(this, "itemsObject", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: itemsObject
        });
        Object.defineProperty(this, "checkboxes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: checkboxes
        });
        Object.defineProperty(this, "userId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: userId
        });
        Object.defineProperty(this, "moduleId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: moduleId
        });
        Object.defineProperty(this, "metaKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: metaKey
        });
        Object.defineProperty(this, "savingLang", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'Saving'
        });
        Object.defineProperty(this, "buttonText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.buttonText = (_a = this.submitButton.textContent) !== null && _a !== void 0 ? _a : 'Save';
        this.savingLang = (_b = this.submitButton.getAttribute('data-js-saving-lang')) !== null && _b !== void 0 ? _b : this.savingLang;
        if (wpApiSettings) {
            this.submitListener();
        }
    }
    submitListener() {
        this.submitButton.addEventListener('click', (event) => {
            event.preventDefault();
            let values = {};
            this.checkboxes.forEach((checkbox) => {
                values[checkbox.value] = checkbox.checked;
            });
            this.patchUser(values);
        });
    }
    patchUser(values) {
        var _a;
        this.handleBeforeSave();
        const endpoint = `${wpApiSettings === null || wpApiSettings === void 0 ? void 0 : wpApiSettings.root}wp/v2/users/${this.userId}`;
        fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-WP-NONCE': (_a = wpApiSettings === null || wpApiSettings === void 0 ? void 0 : wpApiSettings.nonce) !== null && _a !== void 0 ? _a : '',
            },
        })
            .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch existing user data');
            }
            return response.json();
        })
            .then(data => {
            var _a;
            let metaData = data.meta ? data.meta[this.metaKey] : null;
            if (!metaData || typeof metaData !== 'object' || Array.isArray(metaData)) {
                metaData = {};
            }
            metaData[this.moduleId] = values;
            return fetch(endpoint, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-WP-NONCE': (_a = wpApiSettings === null || wpApiSettings === void 0 ? void 0 : wpApiSettings.nonce) !== null && _a !== void 0 ? _a : '',
                },
                body: JSON.stringify({
                    meta: {
                        [this.metaKey]: metaData,
                    }
                }),
            });
        })
            .then(() => {
            this.handleSuccessfullSave(values);
        })
            .catch(error => {
            console.error('Error:', error);
            this.handleFailedSave();
        });
    }
    handleBeforeSave() {
        this.submitButton.disabled = true;
        this.closeButton.disabled = true;
        this.submitButton.textContent = this.savingLang;
    }
    handleFailedSave() {
        this.errorNotice.classList.remove('u-display--none');
        this.submitButton.disabled = false;
        this.closeButton.disabled = false;
        this.submitButton.textContent = this.buttonText;
    }
    handleSuccessfullSave(values) {
        this.submitButton.disabled = false;
        this.closeButton.disabled = false;
        this.submitButton.textContent = this.buttonText;
        this.showOrHideItemsBasedOnSaved(values);
        this.closeButton.click();
    }
    showOrHideItemsBasedOnSaved(values) {
        for (const [key, element] of Object.entries(this.itemsObject)) {
            if (!(key in values)) {
                continue;
            }
            if (values[key]) {
                element.classList.remove('u-display--none');
            }
            else {
                element.classList.add('u-display--none');
            }
        }
    }
}
function initUserEditableList(userEditable) {
    const metaKey = userEditable.getAttribute('data-js-user-editable');
    if (!metaKey) {
        return;
    }
    const userId = userEditable.getAttribute('data-js-user-editable-user');
    const moduleId = userEditable.getAttribute('data-js-user-editable-id');
    const submitButton = userEditable.querySelector('button[type="submit"]');
    const checkboxes = userEditable.querySelectorAll('input[type="checkbox"]');
    const errorNotice = userEditable.querySelector('[data-js-user-editable-error]');
    let itemsObject = {};
    userEditable.querySelectorAll('[data-js-item-id]').forEach(item => {
        const itemId = item.getAttribute('data-js-item-id');
        if (itemId) {
            itemsObject[itemId] = item;
        }
    });
    const closeButton = userEditable.querySelector('button[data-js-cancel-save]');
    if (submitButton && closeButton && userId && moduleId && checkboxes.length) {
        new UserEditableList(submitButton, closeButton, errorNotice, itemsObject, checkboxes, userId, moduleId, metaKey);
    }
}
document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('[data-js-user-editable]').forEach(userEditable => {
        initUserEditableList(userEditable);
    });
});
